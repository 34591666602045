/**
 * @type {Any}
 */
export const channelsConfig = {
  REDDIT: {
    display: "Reddit",
    imgSrc: "/images/channelsIcon/reddit.png",
    description: "Social",
    sectionKey: "available",
    type: "connector",
    formConfig: {
      name: { field: "input", label: { label: "Task Name" }, type: "text" },
      query: { field: "input", label: { label: "Search Reddit" }, type: "text" },
      search_type: {
        field: "listbox",
        label: { label: "Type" },
        options: {
          posts: {
            key: "posts",
            display: "Posts",
          },
          subreddits: {
            key: "subreddits",
            display: "Communities",
          },
        },
        value: "posts",
        children: {
          subreddits: {
            rules: {
              value: "posts",
            },
            field: "multi_input",
            label: {
              label: "Filter Subreddits",
            },
            value: null,
            required: false,
          },
        },
      },
      time_filter: {
        field: "listbox",
        label: {
          label: "Time Filter",
        },
        options: {
          all: {
            key: "all",
            display: "ALL",
          },
          year: {
            key: "year",
            display: "YEAR",
          },
          month: {
            key: "month",
            display: "MONTH",
          },
          week: {
            key: "week",
            display: "WEEK",
          },
          day: {
            key: "day",
            display: "DAY",
          },
          hour: {
            key: "hour",
            display: "HOUR",
          },
        },
        value: "all",
        required: true,
      },
      sort: {
        field: "listbox",
        label: {
          label: "Sort",
        },
        options: {
          top: {
            key: "top",
            display: "TOP",
          },
          hot: {
            key: "hot",
            display: "HOT",
          },
          new: {
            key: "new",
            display: "NEW",
          },
          rising: {
            key: "rising",
            display: "RISING",
          },
        },
        value: "hot",
        required: true,
        invisible: false,
      },
      should_crawl_comments: {
        field: "listbox",
        label: {
          label: "Should crawl comments?",
        },
        options: {
          false: {
            key: false,
            display: "False",
          },
          true: {
            key: true,
            display: "True",
          },
        },
        value: "false",
        required: true,
        invisible: false,
      },
      periodic_crawl_enabled: {
        field: "checkbox",
        label: {
          label: "Crawl data Periodically",
          labelPosition: "right",
        },
        value: false,
        children: {
          periodic_crawl_limit: {
            rules: {
              value: true,
            },
            field: "input",
            type: "number",
            label: {
              label: "Periodic Results Limit",
            },
            required: false,
          },
        },
      },
      one_time_crawl_limit: {
        field: "input",
        label: {
          label: "Historic Results Limit",
        },
        type: "number",
        required: true,
      },
      channel: {
        field: "channel",
        label: "Channel",
        type: "string",
        value: "REDDIT",
        required: false,
        hidden: true,
      },
    },
    apiUrl: "api_v2/data_source/user_task",
    // TODO:use formconfig to generate this
    requestSchema: {
      user_task: {
        name: "",
        periodic_crawl_enabled: "",
        task_meta: [
          {
            query: null,
            search_type: null,
            subreddits: null,
            time_filter: null,
            sort: null,
            crawl_limit: null,
            channel: null,
            one_time_crawl_limit: null,
            periodic_crawl_limit: null,
            should_crawl_comments: null,
          },
        ],
      },
      annotated_data_sources: [],
    },
  },
  HUBSPOT: {
    display: "HubSpot",
    description: "CRM",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imgSrc: "/images/channelsIcon/hubspot.png",
    apiUrl: "api_v2/data_source/connectors/HUBSPOT/authorize",
    landingPageUrl: "/hubspot",
  },

  INTERCOM: {
    display: "Intercom",
    description: "Conversations",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imgSrc: "/images/channelsIcon/intercom.png",
    apiUrl: "api_v2/data_source/connectors/INTERCOM/authorize",
    // landingPageUrl: "/intercom",
  },

  SALESFORCE: {
    display: "Salesforce",
    description: "Cases, LiveChatTranscript",
    type: "connector",
    sectionKey: "available",
    formConfig: {
      domain: {
        field: "input",
        value: null,
        label: {
          label: "Org Domain",
        },
        required: true,
      },
    },
    imgSrc: "/images/channelsIcon/salesforce.png",
    redirectUri: "connect",
    apiUrl: "api_v2/data_source/connectors/SALESFORCE/authorize",
    // landingPageUrl: "/salesforce",
  },

  SURVEYMONKEY: {
    display: "SurveyMonkey",
    description: "Surveys",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imgSrc: "/images/channelsIcon/surveymonkey.png",
    formConfig: {
      survey_titles: {
        field: "multi_input",
        label: {
          label: "Survey Titles",
        },
        value: null,
        required: false,
      },
    },
    apiUrl: "api_v2/data_source/connectors/SURVEYMONKEY/authorize",
    // landingPageUrl: "/surveymonkey",
  },

  TYPEFORM: {
    display: "Typeform",
    description: "Forms and Surveys",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imgSrc: "/images/channelsIcon/typeform.png",
    formConfig: {
      form_titles: {
        field: "multi_input",
        label: {
          label: "Form Titles",
        },
        value: null,
        required: false,
      },
    },
    apiUrl: "api_v2/data_source/connectors/TYPEFORM/authorize",
    landingPageUrl: "/typeform",
  },

  "ZD-SUPPORT": {
    display: "Zendesk Support",
    description: "Support Tickets",
    type: "connector",
    sectionKey: "available",
    formConfig: {
      subdomain: {
        field: "input",
        label: {
          label: "Sub Domain",
        },
        value: null,
        required: true,
      },
      write_back_tags: {
        field: "checkbox",
        label: {
          label: "Allow FreeText AI to write tags into Zendesk.",
          labelPosition: "right",
        },
        value: false,
        required: false,
      },
    },
    redirectUri: "connect",
    imgSrc: "/images/channelsIcon/zd_support.png",
    apiUrl: "api_v2/data_source/connectors/ZD-SUPPORT/authorize",
    landingPageUrl: "/zendesk",
  },

  UPLOADCSV: {
    display: "Upload CSV",
    sectionKey: "available",
    description: "CSV File",
    type: "upload_csv",
    imgSrc: "/images/channelsIcon/csv.png",
  },

  "AZ-US": {
    display: "Amazon US",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "AZ-UK": {
    display: "Amazon UK",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "AZ-IN": {
    display: "Amazon India",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "KKC-JP": {
    display: "Kakaku",
    imgSrc: "/images/channelsIcon/kakaku.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  COSTCO: {
    display: "CostCo",
    imgSrc: "/images/channelsIcon/costco.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  ACE: {
    display: "CostCo",
    imgSrc: "/images/channelsIcon/ace.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "AZ-FR": {
    display: "Amazon France",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "AZ-CA": {
    display: "Amazon Canada",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "AZ-ES": {
    display: "Amazon Spain",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "AZ-DE": {
    display: "Amazon Germany",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "FK-IN": {
    display: "Flipkart",
    imgSrc: "/images/channelsIcon/flipkart.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "BB-US": {
    display: "BestBuy US",
    imgSrc: "/images/channelsIcon/bestbuy.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "BB-CA": {
    display: "BestBuy Canada",
    imgSrc: "/images/channelsIcon/bestbuy.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  HOMEDEPOT: {
    display: "HomeDepot",
    imgSrc: "/images/channelsIcon/hd.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "HOMEDEPOT-CA": {
    display: "Home Depot Canada",
    imgSrc: "/images/channelsIcon/hd.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "TGT-US": {
    display: "Target",
    imgSrc: "/images/channelsIcon/target.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "CPNG-KR": {
    display: "Coupang",
    imgSrc: "/images/channelsIcon/coupang.svg",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "NEWEGG-US": {
    display: "NewEgg US",
    imgSrc: "/images/channelsIcon/newegg.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  PLSTR: {
    display: "Play Store",
    imgSrc: "/images/channelsIcon/play_store.png",
    description: "Apps",
    sectionKey: "available",
  },
  APPSTORE: {
    display: "App Store",
    imgSrc: "/images/channelsIcon/app_store.png",
    description: "Apps",
    sectionKey: "available",
  },

  twitter: {
    display: "Twitter",
    imgSrc: "/images/channelsIcon/twitter.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  youtube: {
    display: "Youtube",
    imgSrc: "/images/channelsIcon/youtube.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  instagram: {
    display: "Instagram",
    imgSrc: "/images/channelsIcon/instagram.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  facebook: {
    display: "Facebook",
    imgSrc: "/images/channelsIcon/facebook.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  tiktok: {
    display: "TikTok",
    imgSrc: "/images/channelsIcon/tictok.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  "AZ-JP": {
    display: "Amazon Japan",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "coming_soon",
    type: "ecommerce",
  },
  "AZ-IT": {
    display: "Amazon Italy",
    imgSrc: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "coming_soon",
    type: "ecommerce",
  },
  SEPHORA: {
    display: "Sephora",
    imgSrc: "/images/channelsIcon/sephora.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  ULTA: {
    display: "Ulta",
    imgSrc: "/images/channelsIcon/ulta.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "MMKTS-DE": {
    display: "MediaMarkt (Germany)",
    imgSrc: "/images/channelsIcon/mediamarkt.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "WMT-US": {
    display: "Walmart US",
    imgSrc: "/images/channelsIcon/walmart.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "CURRYS-UK": {
    display: "Curry's",
    imgSrc: "/images/channelsIcon/currys.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "BLG-FR": {
    display: "Boulanger",
    imgSrc: "/images/channelsIcon/boulanger.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "FNAC-FR": {
    display: "FNAC",
    imgSrc: "/images/channelsIcon/fnac.png",
    description: "eCommerce",
    sectionKey: "coming_soon",
    type: "ecommerce",
  },
  "JBH-AU": {
    display: "JB Hi-Fi",
    imgSrc: "/images/channelsIcon/jbhifi.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
}

export const sections = {
  active: {
    display: "Active",
    description: "These channels are enabled for your account.",
    cardType: "flatCard",
  },

  available: {
    display: "Available",
    description: "Other channels that are available. Get in touch to enable these.",
    cardType: "flatCard",
  },

  coming_soon: {
    display: "Coming Soon",
    description: "Channels and connectors that are in development.",
    cardType: "flatCard",
  },
}
