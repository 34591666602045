import { ReactNode } from "react"
import { joinClassNames } from "../lib/util"

export interface CardProps {
  className?: string
  noPadding?: boolean
  dataPresentation?: any
  children: ReactNode
  styleObj?: object
}

export default function Card({
  noPadding = false,
  className = "",
  children,
  styleObj,
}: CardProps) {
  return (
    <div
      className={joinClassNames(
        "w-full h-full border  rounded-md overflow-auto bg-background ",
        noPadding === true ? "" : "p-2 md:p-3 xl:p-4",
        className
      )}
      style={styleObj}
    >
      {children}
    </div>
  )
}
