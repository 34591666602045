import { FooterSimple } from "@common/footer"
import { THEME } from "configs/theme"

let FOOTER_LINKS = THEME?.footerLinks

export default function LandingSimpleLayout({ children }) {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <main className="flex-grow">{children}</main>
      <footer>
        <FooterSimple footerLinks={FOOTER_LINKS} />
      </footer>
    </div>
  )
}

// context - read option 4 (bonus) here https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout = (page) => <LandingSimpleLayout>{page}</LandingSimpleLayout>
