/*
  - This file will contain functions that manage filter transformations at the application level (beyond the filters component). 
  - Functions that are used by the filters component will reside in components/filters/util.tsx
  - filters.js will be deprecated.
*/

import { OnSelect } from "lib/types"
import { FiltersState, FiltersConfig } from "@common/filters/types"
import { deepCopyObject, isArray, isEmpty, isString } from "@common/lib/util"

export function getFiltersStateFromOnSelect(onSelect: OnSelect) {
  const filtersState: FiltersState = {}

  if (!onSelect || !("filters" in onSelect)) return filtersState

  for (const filterKey in onSelect["filters"]) {
    const filterValue = onSelect["filters"][filterKey]
    filtersState[filterKey] = isArray(filterValue)
      ? filterValue
      : isString(filterValue)
        ? [filterValue]
        : undefined
  }
  return filtersState
}

export function getPageKeyFilters(filters?: FiltersConfig, pageKey?: string) {
  /*
   * If pageKey and pageKey is not in page_keys in filter object, remove the filter from the filters object
   */
  const filteredConfig = deepCopyObject(filters)
  for (const key in filteredConfig) {
    if (Object.prototype.hasOwnProperty.call(filteredConfig[key], "page_keys")) {
      if (
        isArray(filteredConfig[key].page_keys) &&
        !isEmpty(filteredConfig[key].page_keys)
      ) {
        if (!filteredConfig[key].page_keys.includes(pageKey)) {
          delete filteredConfig[key]
        }
      }
    }
  }
  return filteredConfig
}
