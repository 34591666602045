import Button from "@common/buttons/button"
import Svg from "@common/svg"
import React, { ReactNode } from "react"

export default function ToastCard({
  icon,
  closeToast,
  children,
}: {
  icon?: ReactNode
  closeToast?: any
  children?: any
}) {
  return (
    <div className="bg-muted p-3 rounded-md border relative">
      <div className="absolute top-0 right-0">
        <Button size="icon" variant="minimal" onClick={closeToast}>
          <Svg classes="w-4 h-4" name="cross" />
        </Button>
      </div>
      <div className="flex justify-start gap-3 items-center mr-2">
        {typeof icon == "string" ? <Svg classes="w-6 h-6" name={icon} /> : icon}
        <div>{children}</div>
      </div>
    </div>
  )
}

//shared between success toast and promise resolve
export const SuccessToastCardContent = (props: any) => (
  <ToastCard
    {...props}
    icon={<Svg classes="w-6 h-6 text-green-500" name="check-filled" />}
  >
    {props?.children}
  </ToastCard>
)
//shared between error toast and promise reject
export const ErrorToastCardContent = (props: any) => (
  <ToastCard
    {...props}
    icon={<Svg classes="w-6 h-6 text-destructive" name="error" />}
  >
    {props?.children}
  </ToastCard>
)
