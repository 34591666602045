import { useRouter } from "next/router"
import { replaceAll } from "lib/util"
import { getPathWithSentiment } from "lib/navigation"
import { removeQueryParams } from "@common/lib/navigation"
import { isArray, isNullOrEmpty } from "@common/lib/util"
import { removeInvalidQueryParams } from "@common/lib/navigation"
import { getDecodedURIComponent, getUrlParts } from "@common/lib/navigation"
import { deleteKeysFromObject } from "@common/lib/util"

export const WIDGET_FILTERS_QUERY = "widf"

export const localQueryParams = [WIDGET_FILTERS_QUERY, "comment", "compare"]
export const navigationQueryParams = ["navigation"]
export const commonQueryParams = ["ids", "report_id", "cat_id", "widget_id"]

export const appQueryParams = commonQueryParams
  .concat(localQueryParams)
  .concat(navigationQueryParams)

export function removeAppQueryParams(filtersState) {
  if (isNullOrEmpty(filtersState)) return
  else return deleteKeysFromObject(filtersState, appQueryParams)
}

export function isAppQueryParam(key) {
  if (appQueryParams.includes(key)) return true
}

// TODO - deprecate. Start using getQueryObjectFromUrl in @common/lib/navigation.ts
/**
 *
 * @param {string} path
 * @returns {import("@common/filters/types").FiltersState}
 */
export function getFiltersStateFromUrl(path) {
  let filtersState = {}
  if (!path || path.indexOf("?") == -1) return filtersState

  const filters = getUrlParts(path)[1].split("&")

  filtersState = getFiltersStateFromFilters(filters)
  return filtersState
}

// TODO - deprecate. Start using getQueryObjectFromUrl in @common/lib/navigation.ts
export function getFiltersStateFromQueryString(queryString = "") {
  if (queryString.includes("?")) [, queryString] = queryString.split("?")
  const filters = queryString.split("&")
  return getFiltersStateFromFilters(filters)
}

// TODO - deprecate. Start using getQueryObjectFromUrl in @common/lib/navigation.ts
// input = ["time=1mon", "ids=xyz", "stars=1,2,3"]
// output = {'time': ['1mon'], ids: ['xyz'], stars: [1,2,3]}
export function getFiltersStateFromFilters(filters) {
  const filtersState = {}

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]

    if (!filter) continue

    const filterParts = filter.split("=")
    const [filterKey, filterValue] = filterParts

    /* Backend can send "" or null as values in onSelect
     * Hence, state needs to maintain this state, so that it can be handled.
     * Removal of invalid query params can happen at the url construction layer. */
    if (!filterValue) {
      filtersState[filterKey] = filterValue
      //continue // in case of invalid query
    }

    //decoding each query value before putting in filterState
    filtersState[filterKey] = filterValue
      ?.split(",")
      .map((v) => getDecodedURIComponent(v))
  }
  return filtersState
}

// TODO - Cleanup.
// Only functions that act on filtersState or support the filter component should be here. Any function that takes path/router/query or returns path should be in navigation.js.

/**
 *
 * @param {import("next/router").NextRouter} router
 * @returns {string}
 */
export function getUrlQueryString(router) {
  return router.asPath.split("?")[1]
}
export function getFiltersFromPath(path) {
  return path.split("?")[1]
}

export function getWidgetId(router) {
  const { widget_id } = router.query
  return widget_id
}

export function getCustomerId() {
  const router = useRouter()
  return router.query.customerId
}
export function getDocId() {
  const router = useRouter()
  return router.query.doc_id
}
export function getCategoryId() {
  const router = useRouter()
  const { cat_id } = router.query
  if (!cat_id) return
  const catId = decodeURIComponent(cat_id)
  if (typeof catId === "string") return catId?.replace(" ", "+")
  if (isArray(catId)) return catId[0]?.replace(" ", "+")
}
/**
 * @returns {string}
 */
export function getReportId() {
  const router = useRouter()
  const { report_id } = router.query
  return report_id
}
export function getEntityId() {
  const router = useRouter()
  if (!router.query || !("ids" in router.query)) return

  let entityId = router.query.ids.split(",")[0]
  entityId = replaceAll(entityId, " ", "+")
  return entityId
}

/**
 *
 * @param {import("next/router").NextRouter} router
 */
export function getDemo(router) {
  const { demo } = router.query
  return Boolean(demo)
}

/**
 *
 * @param {NextRouter} router
 * @returns {Array<string>}
 */
export function getEntityIds(router) {
  if (!router?.query?.ids) return []
  const entityIds = router.query.ids
    .split(",")
    .map((eid) => eid.trim().replaceAll(" ", "+"))
  return entityIds
}

// queries - > time, ids,
/**
 * removeQueryStringFromPath(path, "time", "ids", "sent")
 *
 */
export function removeQueryStringFromPath(path, ...queries) {
  if (path.indexOf("?") == -1) return path
  let newPath = path
  queries.forEach((queryKey) => {
    newPath = removeQueryParams(newPath, queryKey)
  })
  return newPath
}

export function getFiltersForComments(filters, overrideEntityId) {
  const commentFilters = []
  if (!filters || filters.indexOf("&") == -1 || filters.indexOf("ids=") == -1) {
    commentFilters.push(`ids=${overrideEntityId}`)
  }
  const queries = filters?.split("&")
  queries?.forEach((query) => {
    const [filterKey] = query.split("=")
    if (filterKey == "ids" && overrideEntityId)
      commentFilters.push(`ids=${overrideEntityId}`)
    else if (isCommentFilters(filterKey)) {
      commentFilters.push(query)
    }
  })
  return commentFilters.join("&")
}

export function isCommentFilters(filterKey) {
  if (
    filterKey == "sort" ||
    filterKey == "senti" ||
    filterKey == "text" ||
    filterKey == "ids"
  )
    return true
  return false
}

// TODO - usual steps. convert to object. add/replace. convert to url.
export function addQueryParam(path, param, value) {
  return addFilter(path, param, value)
}

/* TODO - deprecate */
export function addFilter(path, filterKey, filterValue) {
  if (!filterValue) return path
  let newPath = path
  if (path.indexOf("?") != -1) {
    newPath = removeQueryParams(path, filterKey)
  }
  newPath = addFilterIfNotPresent(newPath, filterKey, filterValue)
  return newPath
}

export function removeFilterFromQuerystring(querystring, filterKey) {
  if (!querystring) return
  const path = "?" + querystring
  const newPath = removeQueryParams(path, filterKey)
  let filters = getFiltersFromPath(newPath)
  if (filters[0] == "&") filters = filters.substring(1)
  return filters
}

export function addFilterIfNotPresent(oldPath, filterKey, filterValue) {
  if (
    oldPath.indexOf(`?${filterKey}`) != -1 ||
    oldPath.indexOf(`&${filterKey}`) != -1
  )
    return oldPath
  if (oldPath.indexOf("?") == -1) {
    return oldPath + `?${filterKey}=${filterValue}`
  } else return oldPath + `&${filterKey}=${filterValue}`
}

// returns current path minus the filter specified in argument
export function replaceFilter(oldPath, filterKey, newValue) {
  if (!oldPath) return ""
  let newPath = removeQueryParams(oldPath, filterKey)

  if (!newValue) return newPath

  if (newPath.indexOf("?") == -1) newPath += `?${filterKey}=${newValue}`
  else if (newPath.charAt(newPath.length - 1) === "?")
    newPath += `${filterKey}=${newValue}`
  else newPath += `&${filterKey}=${newValue}`

  return newPath
}

export function replaceFilterInQuerystring(querystring, filterKey, newValue) {
  if (!querystring && !newValue) return

  const path = "?" + (querystring ? querystring : "")
  const newPath = replaceFilter(path, filterKey, newValue)

  let filters = getFiltersFromPath(newPath)
  if (!filters) return newPath

  if (filters[0] == "&") filters = filters.substring(1)

  return filters
}

// todo - move to check in query
export function isFilterInPath(path, filterKey) {
  if (!path) return
  const redirectUrl = getFilterValueInQuerystring(path.toString(), filterKey)
  return !!redirectUrl
}

export function isFilterInQueryPath(query, filterKey) {
  if (Object.keys(query).length == 0) return
  return filterKey in query
}

// input - time, time=1mon&stars=1,2,3
// output - 1mon
// returns last occurance
export function getFilterValueInQuerystring(querystring, filterKey) {
  if (!querystring) return
  const parts = querystring.split("&")
  let value = null
  for (const i in parts) {
    const keyValue = parts[i].split("=")
    if (keyValue[0] == filterKey) value = keyValue[1]
  }
  return value
}

// TODO - deprecate. Use the function getUrlFromQueryObject in @common/lib/navigation.
export function getUrlFromFiltersState(oldPath, filtersState) {
  const pathFiltersState = getFiltersStateFromUrl(oldPath)
  const updatedFiltersState = { ...pathFiltersState, ...filtersState }
  const querystring = getFiltersQuerystringFromFiltersState(updatedFiltersState)

  return getUrlFromQuerystring(oldPath, querystring)
}

export function getUrlFromQuerystring(oldPath, querystring) {
  const [newPath] = getUrlParts(oldPath)
  //return `${newPath}?${querystring}`
  return removeInvalidQueryParams(`${newPath}?${querystring}`)
}

// TODO - deprecate. Use the function getUrlFromQueryObject in @common/lib/navigation.
export function getFiltersQuerystringFromFiltersState(filtersState) {
  let querystring = ""
  for (const filter in filtersState) {
    const filterValues = filtersState[filter]
    if (filterValues?.length > 0) {
      querystring += filter + "="
      for (let i = 0; i < filterValues.length; i++) {
        querystring += encodeURIComponent(filterValues[i]) + ","
      }
      // strip last , if present
      if (querystring[querystring.length - 1] == ",")
        querystring = querystring.substring(0, querystring.length - 1)

      // add &
      querystring += "&"
    }
  }
  // remove last & if present
  if (querystring[querystring.length - 1] == "&")
    querystring = querystring.substring(0, querystring.length - 1)
  return querystring
}

// time manipulation
export function replaceTime(path, newTime) {
  if (!path || !newTime) return path

  let newPath = removeQueryParams(path, "time")
  newPath = replaceFilter(newPath, "time", newTime)
  newPath = addFilterIfNotPresent(newPath, "time", newTime)
  return newPath
}

// replace the filters with query overides and returns a new path with query overides
export function overrideQuery(path, query_override) {
  let newPath = path
  const queryParts = query_override.split("&")
  for (let i = 0; i < queryParts.length; i++) {
    const parts = queryParts[i].split("=")
    const [queryKey, queryValue] = parts
    newPath = replaceFilter(newPath, queryKey, queryValue)
  }
  return newPath
}

// path = /d/corsair/diy/stats
export function replaceCategory(path, query, newCategory) {
  if (!path || !newCategory) return path
  return addFilter(path, "cat_id", newCategory)
}

export function replaceText(path, newText, newSenti) {
  if (!path || !newText) return path
  const newPath = replaceFilter(path, "text", newText)
  return getPathWithSentiment(newPath, newSenti)
}

export function removeAspects(path) {
  let newPath = removeQueryParams(path, "aspect")
  newPath = removeQueryParams(newPath, "text")
  newPath = removeQueryParams(newPath, "senti")
  return newPath
}

export function textURLFriendly(text) {
  /*
  check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
  below code filters slashes
  */
  return encodeURIComponent(text.replace(/(\\|\/)/g, "").replace(/"/g, '\\"'))
}
/* end: text and aspect manipulation */

export function filterObjectByKeyValue(obj, key, value) {
  // ---> SEARCHES OBJECT FOR A SPECIFIC VALUE AGAINST A SPECIFIC KEY
  if (!obj) return null
  const filteredArray = []
  Object.keys(obj).filter((i) => {
    if (obj[i][key] == value) {
      filteredArray.push(obj[i])
    }
  })
  return filteredArray
}

export function isFilterPresent(query, filterKey) {
  if (!query || Object.keys(query).length == 0) return false
  if (filterKey in query) return true
  return false
}

export function appendQuery(queryString, queryKey, queryValue) {
  const searchParams = new URLSearchParams(queryString)
  if (queryKey && queryValue) searchParams.append(queryKey, queryValue)
  return searchParams.toString()
}
