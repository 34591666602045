import {
  getCustomerId,
  getCategoryId,
  getEntityId,
  addFilter,
  getFiltersStateFromQueryString,
} from "./filters"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "tailwind.config"
import { SITE, THEME } from "configs/theme"
import {
  getHomePathForNextStep,
  getPageKey,
  getPathForNewEntity,
  getRedirectPath,
} from "./navigation"
import { removeQueryParams } from "@common/lib/navigation"
import { channelsConfig } from "configs/channels"
import { isArray, isObject, isString } from "@common/lib/util"

const fullConfig = resolveConfig(tailwindConfig)

export function getOldDashboardLink(pageKey) {
  const customerId = getCustomerId()
  const categoryId = getCategoryId()
  const entityId = getEntityId()
  const key = pageKey || "groups"
  const path = `${THEME["seo"]["url"]}/${customerId}/dashboard/${categoryId}/${key}/f/ids=${entityId}&time=1yr`
  return path
}

export function round(number, decimal = 1) {
  if (isNaN(number)) return number

  if (decimal == 0) return Math.round(number)

  return Math.round(number * 10) / 10
}

// replaces all occurances of oldStr with newStr
export function replaceAll(str, oldStr, newStr) {
  if (str == undefined || oldStr == undefined || newStr == undefined) return str

  return str.split(oldStr).join(newStr)
}

// remove from array
export function removeFromArray(array, element) {
  const index = array.indexOf(element)
  if (index > -1) array.splice(index, 1)
  return array
}

export function removeArrayFromArray(array, subArray) {
  let newArray = array
  for (const idx in subArray) {
    const index = array.indexOf(subArray[idx])
    if (index > -1) {
      newArray = removeFromArray(array, subArray[idx])
    }
  }
  return newArray
}

export function removeFromArrayAtPosition(array, position) {
  if (position > -1) return array
}

// Screen Breakpoints
export function isSmall() {
  return screen.width < fullConfig.theme.screens.md.split("px")[0]
}

export function capitalizeFirstLetter(string) {
  if (!string) return

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getCommaSeparatedStrFromArray(arr) {
  if (!arr || arr.length == 0) return
  return arr.join(",")
}

//two arrays. The first array contains values while the second array contains indices of the values which should be removed from the first array.
export function removeMultipleFromArray(valuesArr, removeValFromIdxArr) {
  // sort indexes in descending order
  removeValFromIdxArr.sort(function (a, b) {
    return b - a
  })
  const tempArr = [...valuesArr]
  for (let i = removeValFromIdxArr.length - 1; i >= 0; i--)
    tempArr.splice(removeValFromIdxArr[i], 1)
  return tempArr
}

export const getDefaultCategoryKey = (categoryId, categoryList) => {
  if (!categoryList) return
  if (categoryId && categoryId != "all") {
    return categoryId
  } else {
    const [firstKey] = Object.keys(categoryList)
    return firstKey
  }
}

export function sortObjectsInArray(array, key) {
  // array      ---> EXPECTS AN ARRAY OF OBJECTS
  // key        ---> EXPECTS A KEY PROPERTY BY WHICH TO SORT & GROUP
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    })
  }, {})
}

export const getPaymentRedirectUrl = (path, currentStep, returnPageKey) => {
  const pathSuccess = currentStep
    ? getHomePathForNextStep(path, currentStep + 1)
    : path
  const pathFailure = currentStep ? getHomePathForNextStep(path, currentStep) : path
  const success_url =
    typeof window == "undefined"
      ? ""
      : location.origin + addFilter(pathSuccess, "payment_status", "success")
  const cancel_url =
    typeof window == "undefined"
      ? ""
      : location.origin + addFilter(pathFailure, "payment_status", "fail")
  const project = SITE
  if (!returnPageKey) return { success_url, cancel_url, project }
  const return_url =
    typeof window == "undefined"
      ? ""
      : location.origin +
        removeQueryParams(getRedirectPath(path, returnPageKey), "step")

  return { success_url, cancel_url, return_url, project }
}

export function getReportIdFromEntityObj(entityObj, existingReportId) {
  if (existingReportId) {
    // Check if the entityObj's' report defs include the existing report ID.
    if (
      isArray(entityObj?.report_ids) &&
      entityObj?.report_ids.includes(existingReportId)
    )
      return existingReportId
  }

  // If no existing report ID was provided or it is not found in the report ids,
  // return the ID of the first available report.
  if (isArray(entityObj?.report_ids)) return entityObj?.report_ids.at(0)

  return "topics"
}

export function getRecordCount(entityObj) {
  return entityObj?.num_records
}

export function getSourceFromEntityObj(entityObj) {
  return entityObj?.src_name || entityObj?.src_id || null
}
export function getSourceUrl(entityObj) {
  return entityObj?.url || null
}

export function getCategoryName(entityObj, urlCategoryId, categoryList) {
  /*
  const categoryKey =
    entityObj && entityObj["categories"]
      ? Object.keys(entityObj["categories"])[0]
      : urlCategoryId
  */

  const categoryKey = entityObj?.cat_id || urlCategoryId

  const categoryName =
    categoryKey &&
    categoryList &&
    categoryKey in categoryList &&
    "name" in categoryList[categoryKey]
      ? categoryList[categoryKey]["name"]
      : categoryKey
  return categoryName
}

// CategoryId ->  if categoryId present, use that, else get from object
export function getRedirectUrlFromEntityObj(
  entityObj,
  pageKey,
  router,
  categoryId,
  defaultTimePref
) {
  const catId = categoryId || getCategoryIdFromEntityObj(entityObj)
  const reportId = getReportIdFromEntityObj(entityObj)

  if (entityObj?.available_cat_ids?.indexOf(catId) == -1) return null

  const newPath = getPathForNewEntity(
    router.asPath,
    router.query,
    entityObj?.id,
    catId,
    reportId,
    pageKey,
    defaultTimePref
  )
  return newPath
}

export function getCategoryIdFromEntityObj(entityObj) {
  return entityObj?.cat_id
  /*
  let categoryId =
    entityObj &&
    "categories" in entityObj &&
    Object.keys(entityObj["categories"]).length >= 1
      ? Object.keys(entityObj["categories"])[0]
      : null
  return categoryId
  */
}

export function removeAllSlashes(text) {
  if (!text || typeof text !== "string") return
  return text.replace(/\/|\\/gi, " ")
}

export function getCategoryOptionsForForm(categoryList = {}) {
  let categories = {}
  let isActive = true
  for (const idx in categoryList) {
    const category = categoryList[idx]
    const option = { key: category["cat_id"], display: category["name"] }
    categories = { ...categories, [option.key]: option }
  }
  const keys = Object.keys(categories)
  if (keys.length == 1 && keys.some((key) => key?.includes("general"))) {
    isActive = false
  }
  return { options: categories, isActive }
}

// input -> list of existing ids (optional)
// output -> id that doesn't match any of the existing ids
export function getUniqueId(existingIds) {
  let uId = Math.random().toString(36).slice(-10)
  if (existingIds && existingIds.indexOf(uId) != -1) uId = getUniqueId(existingIds)
  return uId
}

export function objIncludes(object, value) {
  return !object || !value ? false : Object.values(object).includes(value)
}
export function isUrl(link) {
  if (!link) false
  return /^https?:\/\/.+$/.test(link)
}

// input -> sku. output -> "Product Report"
// input -> sku_ppt. output -> "Product Report"
export function getPptReportName(reportId, reportsData) {
  if (!reportId || !reportsData) return "Report"

  const newReportId =
    reportId.indexOf("_ppt") != -1 ? reportId.split("_ppt")[0] : reportId
  return newReportId in reportsData ? reportsData[newReportId].name : "Report"
}

// input -> sku. output -> sku_ppt
// input -> sku_ppt. output -> sku_ppt
export function getPptConfigName(reportId) {
  return reportId?.indexOf("_ppt") != -1 ? `${reportId}` : `${reportId}_ppt`
}

export function getMenuConfig(path, config, sourceList) {
  const newConfig = {}
  for (const key in config) {
    if (Object.hasOwnProperty.call(config, key)) {
      let menus = config[key]
      if (key === "top")
        menus = menus.filter((menu) =>
          getIsEcommerceEnabled(sourceList) ? true : menu.key !== "compare"
        )
      menus?.forEach((menuItem) => {
        let { key } = menuItem
        if (menuItem.submenu_redirect_key) key = menuItem.submenu_redirect_key
        if (!key) menuItem.url = null
        else menuItem.url = getRedirectPath(path, key)
      })
      newConfig[key] = menus
    }
  }
  return newConfig
}

export function getSubMenuConfig(path, config, sourceList) {
  if (config) {
    const items = config[0]?.items
    const newItems = []
    if (items) {
      items.forEach((menuItem) => {
        const isEnabled = getIsEcommerceEnabled(sourceList)
          ? true
          : menuItem.key !== "manage/catalog"
        if (isEnabled)
          if (!menuItem.url) {
            const url = getRedirectPath(path, menuItem.key)
            newItems.push({ ...menuItem, url })
          }
      })
    }

    /*     const newItems = items?.map((menuItem) => {
      if (!menuItem.url) {
        const url = getRedirectPath(path, menuItem.key)
        return { ...menuItem, url }
      }
      return menuItem
    }) */
    return [{ ...config[0], items: newItems }]
  }
  return config
}

const rowHeightScaleDownFactor = 2 //when changing this, remember to change common_slide pageHeight. Also, all configs/dashboard/*.json.
const v1RowHeightScaleDownFactor = 8
const pageHeight = 12
const v1PageHeight = 32
const gridCols = 12
const v1GridCols = 24
const ROW_HEIGHT = 100 / rowHeightScaleDownFactor
const ROW_HEIGHT_V1 = 100 / v1RowHeightScaleDownFactor
const unitSpacing = 10

/**
 *
 * @param {import("lib/types").ReportConfig | undefined} config
 * @returns {{ slidePageHeight: number, slidePageWidth: number, rowHeight: number, cols: number, unitSpacing: number }}
 */
export function getReportConstants(config) {
  const reportVersion = config?.version

  const slidePageHeight = reportVersion === 1 ? v1PageHeight : pageHeight
  const slidePageWidth = reportVersion === 1 ? v1GridCols : gridCols
  const rowHeight = reportVersion === 1 ? ROW_HEIGHT_V1 : ROW_HEIGHT
  const cols = reportVersion === 1 ? v1GridCols : gridCols

  return {
    slidePageHeight,
    slidePageWidth,
    rowHeight,
    cols,
    unitSpacing,
  }
}
export function showEntitySelector(query, pageKey) {
  if (!query || !pageKey) return

  /*
  if (pageKey == "stats") {
    if (!("report_id" in query)) return true
    else if (isReportTemplate && (!("ids" in query) || !("cat_id" in query)))
      return true
  } else*/
  if (
    (pageKey == "explore" || pageKey == "search" || pageKey == "compare") &&
    (!("ids" in query) || !("cat_id" in query))
  ) {
    return true
  } else return
}

// TODO - move to lib/navigation.
export function getHomePath(custId) {
  if (custId) return `/d/${custId}/${THEME["homePath"]}`
}

export function getCatAndEntityIdFromKey(key) {
  if (!key) return []
  if (!key.includes("/")) return [undefined, key]
  return key.split("/")
}

export const getIsEcommerceEnabled = (sourceList) => {
  let isEnabled = false
  if (!sourceList) return isEnabled
  Object.keys(sourceList).forEach((key) => {
    const channel = channelsConfig[key]
    if (channel?.type === "ecommerce") {
      isEnabled = true
    }
  })
  return isEnabled
}

/**
 *
 * @param {string} aspect
 * @returns {string}
 */
export const getDoubleEncodedAspect = (aspect) => {
  return encodeURIComponent(encodeURIComponent(aspect))
}
/**
 *
 * @param {string} path
 * @returns {string}
 */

export const getChannelImage = (sourceKey) => {
  const channelImages = {
    "az-in": "/images/channelsIcon/amazon.png",
    "az-us": "/images/channelsIcon/amazon.png",
    "az-fr": "/images/channelsIcon/amazon.png",
    "az-de": "/images/channelsIcon/amazon.png",
    "az-uk": "/images/channelsIcon/amazon.png",
    "bb-us": "/images/channelsIcon/bestbuy.png",
    "fk-in": "/images/channelsIcon/flipkart.png",
    "newegg-us": "/images/channelsIcon/newegg.png",
    reddit: "/images/channelsIcon/reddit.png",
  }

  const defaultImage = "/images/channelsIcon/datasource.png"
  return !sourceKey
    ? defaultImage
    : channelImages[sourceKey.trim().toLowerCase()] ?? defaultImage
}

//TODO: moved to common
export function getObjectFromJSONString(jsonString) {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    return {} // if parsing fails, it's not a valid JSON string , returning an empty object
  }
}

export function getIsReportIdPresentInEntity(entityObj, reportId) {
  return entityObj?.report_ids?.some((id) => id == reportId)
}

export function isHomePage(path) {
  const pageKey = getPageKey(path)
  return pageKey?.includes("home")
}

export function getObjectFromQueryOverride(queryOverride) {
  return isString(queryOverride)
    ? getFiltersStateFromQueryString(queryOverride)
    : isObject(queryOverride)
      ? queryOverride
      : {}
}
