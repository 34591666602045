import { useRouter } from "next/router"
import { useEffect } from "react"

export function BeaconLoader() {
  const router = useRouter()
  const path = router.pathname
  const blackListedUrl = [
    "/d/[customerId]/manage/tasks/create",
    "/d/[customerId]/widget",
  ]

  useEffect(() => {
    //☢️ custom handling for Crisp Chat Bubble.
    const adjustCrispPosition = () => {
      const crispRootElement = document.querySelector(".crisp-client")
      // const buttonElement = document.querySelector(".crisp-client .cc-1m2mf")
      // if (buttonElement) {
      //   buttonElement.setAttribute("id", "chat-bubble")
      // }
      if (crispRootElement) {
        if (blackListedUrl.includes(path)) {
          crispRootElement.style.display = "none"
        } else {
          crispRootElement.removeAttribute("data-position-reverse")
          crispRootElement.style.display = ""
        }
      }
    }
    adjustCrispPosition()
    // Optional: To account for any async loading or delays in initializing the Crisp widget
    const timeoutId = setTimeout(adjustCrispPosition, 2000)
    return () => clearTimeout(timeoutId) // Cleanup the timeout if the component is unmounted.
  }, [path])

  //☢️ custom handling for HelpScout Chat Bubble.
  // useEffect(() => {
  //   if (blackListedUrl.includes(path)) {
  //     if (typeof window != "undefined" && window.Beacon) {
  //       window.Beacon("config", { display: { position: "left" } })
  //     }
  //   } else {
  //     if (typeof window != "undefined" && window.Beacon) {
  //       window.Beacon("config", { display: { position: "right" } })
  //     }
  //   }
  // }, [path])
  // if (typeof window != "undefined" && window.Beacon) {
  //   window.Beacon("config", { color: "#0969DA" })
  // }
  // return null

  return null
}
