import { useRouter } from "next/router"
import {
  addFilter,
  replaceFilter,
  addFilterIfNotPresent,
  getUrlFromFiltersState,
  getFiltersQuerystringFromFiltersState,
  navigationQueryParams,
} from "./filters"
import { THEME } from "configs/theme"
import { getObjectFromJSONString, getReportIdFromEntityObj } from "./util"
import { getFiltersStateFromQueryString } from "lib/filters"
import {
  isObjectsEqual,
  filterObjects,
  getQueryParamsFromUrl,
  isNullOrEmpty,
  filterUndefinedFromObject,
  isArray,
  getQueryString,
} from "@common/lib/util"
import { removeQueryParams, getDecodedURIComponent } from "@common/lib/navigation"
import { getFiltersStateFromUrl, commonQueryParams } from "lib/filters"
import { getFiltersStateFromOnSelect } from "lib/filtersNew"
import { localQueryParams } from "lib/filters"
import { getPathWithParams, getFilterValue } from "@common/lib/navigation"

// TODO - Cleanup.
// Only functions that deal with the url or manipulate the url should be here.
// Layer #0 - Functions to replace individual parts of url e.g replaceFilterValue, replaceFirstFilterValue
// Layer #1 - Specific Utility Functions e.g isDashboard, , etc
// Layer #2 - Functions to redirect to individual pages given current page context e.g getManagePlansUrl or getDashboardLinkFromParts.

// provide the newFullPageKey or (newPageKey & newSubPageKey). If not provided, the current fullPageKey is kept as is.
export function getPathForNewEntity(
  path,
  query,
  entityId,
  categoryId,
  reportId,
  overridePageKey,
  time
) {
  const _time = query.time || time
  path = overridePageKey ? replacePageKey(path, overridePageKey) : path

  /* this creates a minified url for new entity by discarding any previously selected filters */
  /*
  path = addFilter(path.split("?")[0], "ids", entityId)
  path = addFilter(path, "report_id", reportId)
  path = addFilter(path, "cat_id", categoryId)
  path = addFilter(path, "time", _time)
  path =
    pageKey?.toLowerCase() == "search" && query?.text
      ? addFilter(path, "text", query.text)
      : path
  */

  /* below code just replaces the new filters. doesn't remove all others */
  path = replaceFilter(path, "ids", entityId)
  path = replaceFilter(path, "report_id", reportId)
  path = replaceFilter(path, "cat_id", categoryId)
  path = replaceFilter(path, "time", _time)
  path = getPathWithoutLocalQueryParams(path)
  return path
}

export function replaceCategoryId(path, newCategoryId) {
  return addFilter(path, "cat_id", newCategoryId)
}

export function getRedirectPathForNewCategory(
  path,
  query,
  entityObj,
  newCategoryId,
  time,
  existingReportId = ""
) {
  const _time = query.time || time
  const reportId = getReportIdFromEntityObj(entityObj, existingReportId)
  let newPath = replaceCategoryId(path, newCategoryId)
  newPath = replaceReportId(newPath, reportId, query)
  newPath = removeQueryParams(newPath, "label_sub_cat")
  newPath = getPathWithoutLocalQueryParams(newPath)
  newPath = addFilter(newPath, "time", _time)
  return newPath
}
// given new page key (groups, explore, etc), constructs the link
// entityObj only required to find reportId for stats redirect
export function getRedirectPath(
  url,
  newPageKey,
  onlyCommonParams = false,
  onlyNavigationParams = false
) {
  if (newPageKey == "help") return THEME["helpLink"]
  let path = url
  path = replacePageKey(path, newPageKey)
  path = onlyNavigationParams
    ? getPathWithoutLocalQueryParams(path)
    : getPathWithoutLocalAndNavQueryParams(path)

  if (onlyCommonParams || onlyNavigationParams) {
    let params = onlyCommonParams ? commonQueryParams : []
    if (onlyNavigationParams) params = params.concat(navigationQueryParams)
    path = getPathWithParams(path, params)
  }
  return path
}

export function getRedirectPathForReport(oldPath, reportId) {
  return addFilter(oldPath, "report_id", reportId)
}

// returns page context e.g explore, 'manage', 'manage/settings' 'manage/topics/create' etc
export function getPageKey(path) {
  if (path) {
    const [pathWithoutQuerystring] = path.split("?")
    const parts = pathWithoutQuerystring.split("/")
    parts.splice(0, 3)
    const key = parts.join("/")
    return key
  }
}

export function replacePageKey(path, newPageKey) {
  if (path) {
    const pageKey = getPageKey(path)
    const parts = path.split("/")
    const basePath = parts.slice(0, 3).join("/")
    const pagePath = parts.slice(3, parts.length).join("/")
    const newPagePath = pagePath.replace(
      new RegExp("\\b" + pageKey + "\\b"),
      newPageKey
    )
    return basePath.concat("/", newPagePath)
  }
}

export function getPublicReportURL(sharedToken, query = "") {
  const origin = typeof window != "undefined" && location.origin
  let path = `/d/shared/${sharedToken}`
  if (query) path += `?${query}`
  if (path && origin) return `${origin}${path}`
}

// incase of public_reports {shared_token} will be pageKey
export function getShareableUrlId(queryObject = {}) {
  const { shared_id } = queryObject
  return shared_id
}

/**
 *
 * @param { import("next/router").NextRouter} router
 * @param { import("lib/types").Report} report
 * @returns {string}
 */
export function getSectionIdFromPath(router, report) {
  const [firstKey] = Object.keys(report?.config?.sections || {})
  return getQueryObject(router, "navigation")?.sectionId || firstKey || "default" // use default incase the report config doesn't exist
}

/**
 *
 * @param { import("next/router").NextRouter} router
 * @param { string} sectionId
 * @returns {string}
 */
export function getPathForNewSection(router, sectionId) {
  return getPathWithQueryObject(router, { sectionId }, "navigation")
}

export function getHomePathForNextStep(path, nextStep) {
  return addFilter(path, "step", nextStep)
}

/**
 *
 * @returns {string} reportId
 */
export function getReportId() {
  const router = useRouter()
  const { report_id } = router.query
  return report_id instanceof Array ? report_id.at(-1) : report_id
}

export function replaceReportId(path, newReportId) {
  const newPath = addFilter(path, "report_id", newReportId)
  return newPath
}

export function getStatsPageUrl(path, query) {
  const newPath = replacePageKey(path, "stats", query)
  return newPath
}

export function getManagePlansUrl(path, query) {
  const newPath = replacePageKey(path, "settings/plans", query)
  return newPath
}

export function getHomePageUrl(customerId) {
  return `/d/${customerId}/home`
}

export function getMeetPageUrl(path) {
  return replacePageKey(path, "meet")
}

export function getChannelsPageUrl(path) {
  return replacePageKey(path, "channels")
}

export function getManageChannelsPageUrl(path) {
  return replacePageKey(path, "manage/channels")
}

export function getSourcesPageUrl(path) {
  return replacePageKey(path, "manage/tasks")
}
export function getCreateSourcesPageUrl(path) {
  return replacePageKey(path, "manage/tasks/create")
}

export function getComparePageUrl(path, ids) {
  const oldPath = path
  if (!path || !Array.isArray(ids)) return path
  const compare = ids.map((eid) => {
    const path = replaceFilter(oldPath, "ids", eid)
    const query = getFiltersStateFromQueryString(getQueryString(path))
    return {
      query,
    }
  })

  return addFilter(oldPath, "compare", encodeURIComponent(JSON.stringify(compare)))
}

export function getDashboardLinkFromParts(
  custId,
  pageKey,
  querystring,
  cat_id,
  report_id
) {
  if (!custId || !pageKey) return null
  let path = `/d/${custId}/${pageKey}`
  path += querystring ? `?${querystring}` : ""

  /**
   * We added these checks to ensure that old bookmarks works
   * TODO: can remove  when all bookmarks are migrated
   */
  if (!path.includes("cat_id=")) path = addFilter(path, "cat_id", cat_id)
  if (!path.includes("report_id=")) path = addFilter(path, "report_id", report_id)
  return path
}

/* LAYER #1 */

export function isDashboard(path) {
  return path.indexOf("/d/") != -1
}

export function isUrlsEqual(url1, url2) {
  if (!url1 || !url2) return false
  if (url1.substring(0, url1.indexOf("?")) !== url2.substring(0, url2.indexOf("?")))
    return false

  const obj1 = getQueryParamsFromUrl(url1)
  const obj2 = getQueryParamsFromUrl(url2)
  if (!isObjectsEqual(obj1, obj2)) return false
  return true
}

/* LAYER #1 */

// returns the entityKey which is categoryId/entityId
export function getEntityKey(entityId = "", categoryId) {
  if (entityId.indexOf("/") == -1) return `${categoryId}/${entityId}`
  else {
    const entity = entityId.split("/")[1]
    return `${categoryId}/${entity}`
  }
}

export function appendEntityId(path, existingIds, newId) {
  return replaceFilter(path, "ids", `${existingIds.join(",")},${newId}`)
}

export function replaceEntityId(path, existingIds, idToReplace, replacementId) {
  existingIds = existingIds.map(function (item) {
    return item == idToReplace ? replacementId : item
  })
  return replaceFilter(path, "ids", `${existingIds.join(",")}`)
}

export function resetEntityId(path, replacementId) {
  return replaceFilter(path, "ids", `${replacementId}`)
}

export function removeEntityId(path, existingIds, idToRemove) {
  const updatedIds = existingIds.filter((id) => id !== idToRemove)
  return replaceFilter(path, "ids", `${updatedIds.join(",")}`)
}

export function removeEntityIdCatId(path) {
  return removeQueryParams(path, ["ids", "cat_id"])
}

export function getPathWithSentiment(newPath, newSenti) {
  return newSenti
    ? addFilterIfNotPresent(newPath, "senti", newSenti)
    : removeQueryParams(newPath, "senti")
}

// <---- FOR TRI ---->
export function getCountry(router) {
  const { country } = router.query
  return country || "us"
}
/* LAYER #0 */

export function removeFilterValue(router, filters, filter, tag) {
  const path = router.asPath
  const queryString = getQueryString(path)
  if (!path || !tag || !queryString) return path

  const queryState = getFiltersStateFromQueryString(queryString)
  const tagValue = queryState[filter]
  if (!isArray(tagValue) || !tagValue.includes(tag)) return path
  tagValue.splice(tagValue.indexOf(tag), 1)
  queryState[filter] = tagValue
  return getUrlFromFiltersState(path, queryState, filters)
}

/*---------------------LOCAL QUERY HANDLER FUNCTIONS ---------------------- */

// Serialize query string
export const serializeObjectToQueryString = (queryObject) => {
  if (isNullOrEmpty(queryObject)) return ""
  const jsonString = JSON.stringify(queryObject)
  return encodeURIComponent(jsonString)
}

// Deserialize object
export const deserializeQueryStringToObject = (encodedQuery) => {
  if (!encodedQuery) return {}
  const jsonString = getDecodedURIComponent(encodedQuery)
  return getObjectFromJSONString(jsonString)
}
export const getPathWithoutLocalQueryParams = (path) => {
  return removeQueryParams(path, localQueryParams)
}
export const getPathWithoutLocalAndNavQueryParams = (path) => {
  return removeQueryParams(path, localQueryParams.concat(navigationQueryParams))
}

// TODO - take in path, convert to filtersState, then next steps.
export function getQueryObject(router, queryKey) {
  if (!router || !queryKey) return {}
  const queryStringValue = getFilterValue(router.query, queryKey)
  return deserializeQueryStringToObject(queryStringValue) || {}
}

// TODO - take in path, convert to filtersState, then next steps.
export function getPathWithQueryObject(router, state, queryKey) {
  const existingQueryObject = getQueryObject(router, queryKey)
  return addFilter(
    router.asPath,
    queryKey,
    serializeObjectToQueryString({ ...existingQueryObject, ...state })
  )
}

export function getCommentQueryObject(router) {
  return getQueryObject(router, "comment")
}
export function getCompareQueryObject(router) {
  return getQueryObject(router, "compare")
}

// TODO - deprecate (no more querystring based operations)
export function getQueryStringForCommentQuery(router) {
  const queryObject = getCommentQueryObject(router)
  return getFiltersQuerystringFromFiltersState(
    getFiltersStateFromOnSelect({
      filters: queryObject,
    })
  )
}

// TODO - this should ideally just wrap getPathWithQueryObject
export function getPathWithCommentQueryObject(
  router,
  queryObject = {},
  keepExisting = true
) {
  const commentQueryObject = getCommentQueryObject(router)
  const newCommentQueryObject = filterUndefinedFromObject({
    ...(keepExisting ? commentQueryObject : {}),
    ...queryObject,
  })
  return replaceFilter(
    router.asPath,
    "comment",
    serializeObjectToQueryString(newCommentQueryObject)
  )
}
/**
 *This is used get get filters state from flattened on_select object
 * @param {Router} router
 * @param { any} queryObject
 * @param {any} filters -> not require to pass this.
 */
export function getFiltersStateFromQueryObject(router, queryObject = {}) {
  const path = getPathWithoutLocalAndNavQueryParams(router.asPath)
  const filtersState = getFiltersStateFromUrl(path)
  const newFilterState = getFiltersStateFromOnSelect({
    filters: { ...queryObject },
  })
  return { ...filtersState, ...newFilterState }
}
