import { ReactNode } from "react"
import { ToastOptions, toast } from "react-toastify"
import { ToastContainer, ToastContainerProps } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToastCard, {
  ErrorToastCardContent,
  SuccessToastCardContent,
} from "@common/toast/toastCard"
import Loader from "@common/loader"
import Svg from "@common/svg"
import { isFunction } from "@common/lib/util"

export interface StatusOptions {
  pending?: string
  success?: string
  error?: string
}
export interface ToastOptionsType {
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left"
  autoClose?: number | false
  hideProgressBar?: boolean
  closeOnClick?: boolean
  pauseOnHover?: boolean
  draggable?: boolean
  pending?: string
  success?: string
  error?: string
  onClose?: () => void
  type?: "info" | "success" | "warning" | "error" | "default" | "loading"
  render?: string
  icon?: ReactNode
}

// showToast displays a toast notification with a specified content, type, and options.
export const showToastAdapter = (
  content: ReactNode,
  options: ToastOptionsType
): number | string => {
  let toastId
  const _options = { ...options, closeButton: false } as ToastOptions
  if (typeof content === "string") {
    // will render content with any toast style (defined in ToastCard)
    switch (options.type) {
      case "info":
        toastId = toast.info(
          <ToastCard icon={<Svg classes="w-6 h-6 text-accent" name={"info"} />}>
            {content}
          </ToastCard>,
          _options
        )
        break
      case "success":
        toastId = toast.success(
          <SuccessToastCardContent>{content}</SuccessToastCardContent>,
          _options
        )
        break
      case "warning":
        toastId = toast.warning(
          <ToastCard icon={<Svg classes="w-6 h-6 text-warning" name={"warning"} />}>
            {content}
          </ToastCard>,
          _options
        )
        break
      case "error":
        toastId = toast.error(
          <ErrorToastCardContent>{content}</ErrorToastCardContent>,
          _options
        )
        break
      case "loading":
        toastId = toast.loading(
          <ToastCard icon={<Loader />}>{content}</ToastCard>,
          _options
        )
        break
      default:
        toastId = toast(<ToastCard>{content}</ToastCard>, _options)
    }
  } else {
    // will render content without any toast style.
    toastId = toast(content, _options)
  }
  return toastId
}

// showPromiseToast wraps a toast notification around a promise, displaying it based on the promise's state.
export const showPromiseToastAdapter = async (
  promiseOrFunction: Promise<any> | (() => Promise<any>),
  statusOptions: StatusOptions,
  options: ToastOptionsType
) => {
  const loadingToastId = showToastAdapter(statusOptions.pending, {
    ...options,
    type: "loading",
  })
  const isFn = isFunction(promiseOrFunction)
  const response = isFn ? await promiseOrFunction() : await promiseOrFunction
  const isSuccess = response?.data?.status === "SUCCESS"

  toast.update(loadingToastId, {
    ...options,
    icon: null,
    render: isSuccess ? (
      <SuccessToastCardContent>{statusOptions.success}</SuccessToastCardContent>
    ) : (
      <ErrorToastCardContent>{statusOptions.error}</ErrorToastCardContent>
    ),
    type: isSuccess ? "success" : "error",
    isLoading: false,
    autoClose: options?.autoClose || 3000,
  })

  return response
}

// updateToast updates a specific toast with new options.
export const updateToastAdapter = (
  toastId: number | string,
  options: ToastOptionsType
) => {
  const isSuccess = options.type == "success"
  toast.update(toastId, {
    ...options,
    icon: null,
    render: isSuccess ? (
      <SuccessToastCardContent>{options.render}</SuccessToastCardContent>
    ) : (
      <ErrorToastCardContent>{options.render}</ErrorToastCardContent>
    ),
    type: isSuccess ? "success" : "error",
    isLoading: false,
    autoClose: options?.autoClose || 3000,
  })
}

// clearToast dismisses a specific toast.
export const clearToastAdapter = (toastId: number | string) => {
  toast.dismiss(toastId)
}

// ToastContainerWrapper is a wrapper component around react-toastify's ToastContainer.
export const ToastContainerWrapper = (props: ToastContainerProps) => {
  return <ToastContainer {...props} />
}
